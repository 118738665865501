import axios from "axios";

const Get=async(url)=>{

    
    let sendResponse;

    console.log("response ");

    await axios.get(url).then(
        (response)=>{
            sendResponse=response.data;
        },
        (error)=>{
            console.log("error ",error);
            sendResponse={status:5};
        }
    )
    return sendResponse;
}
export default Get;