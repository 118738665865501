import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  //Use to send on other route/page
  const navigate=useNavigate();

  //Method to handle navigation of sidebar
  const handleNavigate=(route)=>{
    navigate(route);
  }

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-inner">
          <p>MAIN</p>
        </div>
        {/* <!-- 1 --> */}
        <button
          className="tablinks"
          onClick={()=>{
            handleNavigate("/logs");
          }}
          id="defaultOpen"
        >
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Billing Logs</span>
        </button>
        {/* <!-- 2 --> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/revenue");
        }}>
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Revenue</span>
        </button>
        <button className="tablinks" onClick={()=>{
          handleNavigate("/subUnsubData");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span> Sub & Churn</span>
        </button>
        <button className="tablinks" onClick={()=>{
          handleNavigate("/airtimeLogs");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Airtime Logs</span>
        </button>
        {/* <button className="tablinks" onClick={()=>{
          handleNavigate("/bulkUnsub");
        }}>
          <span>
            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          </span>{" "}
          <span>Bulk Unsub</span>
        </button>
        <button className="tablinks" onClick={()=>{
          handleNavigate("/changePassword");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span> Change Password</span>
        </button> */}
      </div>
    </>
  );
};
export default Sidebar;