import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendSubAndUnsubDataApi } from "../Data/Api";
import Get from "../Request/Get";
import {options} from "../Data/LoaderData";
import moment from "moment/moment";


const SubUnsub = () => {

  //Hook to store data
  const[data,setData]=useState({});
 const [sendDate,setSendDate] = useState({});

  const[dates,setDates]=useState({
    to:moment(new Date()).format("yyyy-MM-DD")
    ,from:moment().subtract(0,'days').format("yyyy-MM-DD")
    });

  //to load on start
  useEffect(()=>{
    hitOnBackend();
    // eslint-disable-next-line
  },[]);

  //Method to hit on backend
  const hitOnBackend=()=>{
    const url = sendSubAndUnsubDataApi+"?date="+dates.from;
    let promise=Get(url);
    promise.then(e=>
      {
      console.log("e",e);
      handleResponse(e);
    })
  }

//Method to handle response
const handleResponse=(e)=>{
//   console.log("e ",e);

  if(e.status===5)
  {
    toast.error("Network Error");
  }
  else if(e.status===3)
  {
    toast.error("Something Went Wrong");
  }
  else if(e.status===4)
  {
    toast.error(e.message);
  }
  else if(e.status===1)
  {
    //ok

    setData({date:e.date,totalSub:e.totalSub,newSub:e.newSub,newChargedSub:e.newChargedSub,totalUnsub:e.totalUnsub,todayUnsub:e.todayUnsub,DailySub:e.DailySub,WeeklySub:e.WeeklySub,MonthlySub:e.MonthlySub,dailyRen:e.dailyRen,weeklyRenPack:e.weeklyRenPack,monthlyPackRen:e.monthlyPackRen,totalRen:e.totalRen});
    console.log("Data",data);
    setDivState('none');
  }
}


//Loader Stuff
let Loader=require('react-loader');

//Hook to store starting div state(loading div)
const[divState,setDivState]=useState('block');

//State for Loader
// eslint-disable-next-line 
const[loaderState,setLoaderState]=useState(false);


const handleSearch =()=>
{
  console.log("date",dates.from);
  setDivState('block');
  hitOnBackend();
}

  return (
    <>

      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Sub & Churn</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">

          
          <span className="main-date-form">
          <div className="date-inner date-1-sec">
              
          <div className="end-date">
      <label htmlFor="from">Select Date:</label>
      <input
        type="date"
        id="from"
        name="trip-start"
        onChange={(e)=>{                    
          setDates({...dates,from:e.target.value})
        }}
      />
    </div>
              {/* <div className="end-date">
                <label htmlFor="to">End Date:</label>
                <input
                  type="date"
                  id="to"
                  name="trip-start"
                  onChange={(e)=>{                    
                    setDates({...dates,to:e.target.value})
                  }}
                />
              </div> */}

              <div className="date-search-btn">
                <button type="submit" onClick={()=>{
                  handleSearch();
                }}>Search</button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Sub & Churn</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                  <tr>
                    <th>date</th>
                    <th>Total Sub</th>
                    <th>New Sub</th>
                    <th>New Charged Sub</th>
                    <th>Total Churn</th>
                    <th>Churn</th>
                  </tr>
                  <tr>
                    <td>{data.date}</td>
                    <td>{data.totalSub}</td>
                    <td>{data.newSub}</td>
                    <td>{data.newChargedSub}</td>
                    <td>{data.totalUnsub}</td>
                    <td>{data.todayUnsub}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </div>

                {/* For All - Data */}

         {/* <!-- date --> */}
         <div className="date-form" style={{marginTop : '70px'}}>

          
<span className="main-date-form">
<div className="date-inner date-1-sec" style={{display :'none'}}>
    
    <div className="end-date">
      <label htmlFor="from">Select Date:</label>
      <input
        type="date"
        id="from"
        name="trip-start"
        onChange={(e)=>{                    
          setDates({...dates,from:e.target.value})
        }}
      />
    </div>
    {/* <div className="end-date">
      <label htmlFor="to">End Date:</label>
      <input
        type="date"
        id="to"
        name="trip-start"
        onChange={(e)=>{                    
          setDates({...dates,to:e.target.value})
        }}
      />
    </div> */}

    <div className="date-search-btn" style={{display:'none'}}>
      <button type="submit" onClick={()=>{
        handleSearch();
      }}>Search</button>
    </div>
  </div>

  {/* <!-- user --> */}
  <div className="title-ic">
    <p>
      <span>
        <i className="fa fa-user" aria-hidden="true"></i>
      </span>{" "}
      <span>All Report</span>
    </p>
  </div>

  {/* <!-- slect box --> */}
  <div className="main-box">

    {/* <!-- table --> */}
    <div className="table-sec">
      <table className="main-table">
        <tbody>
        <tr>
          <th>Date</th>
          <th>Total Sub</th>
          <th>New Subs</th>
          <th>New Subs Charged</th>
          <th>New Sub Daily</th>
          <th>New Sub Weekly</th>
          <th>New Sub Monthly</th>
          <th>Daily Package Renewals</th>
          <th>Weekly Package Renewals</th>
          <th>Monthly Package Renewals</th>
           <th>Total Renewals</th>
        </tr>
        <tr>
          <td>{data.date}</td>
          <td>{data.totalSub}</td>
          <td>{data.newSub}</td>
          <td>{data.newChargedSub}</td>
          <td>{data.DailySub}</td>
          <td>{data.WeeklySub}</td>
          <td>{data.MonthlySub}</td>
          <td>{data.dailyRen}</td>
          <td>{data.weeklyRenPack}</td>
          <td>{data.monthlyPackRen}</td>
          <td>{data.totalRen}</td>
          {/* <td>{data.totalUnsub}</td>
          <td>{data.todayUnsub}</td> */}
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</span>
</div>
      </div>
    </>
  );
};
export default SubUnsub;