import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendWinnersApi } from "../Data/Api";
import Get from "../Request/Get";
import {options} from "../Data/LoaderData";
import moment from "moment/moment";

//Logs Page
const AirtimeLogs = () => {

const[dates,setDates]=useState({
    to:moment(new Date()).format("yyyy-MM-DD")
    ,from:moment().subtract(2,'days').format("yyyy-MM-DD")
    });

  //Hook to store table data
  const[data,setData]=useState([]);

  //to run on load
  useEffect(()=>{
    hitOnBackend();
    // eslint-disable-next-line
  },[]);

  //Method to hit on backend & fetch data
  const hitOnBackend=()=>{
    let promise=Get(`${sendWinnersApi}?fromDate=${dates.from}&toDate=${dates.to}`);
    promise.then(e=>{
        handleWinnersResponse(e);
    })
  }

  //Method to handle winners response
  const handleWinnersResponse=(e)=>{

    setDivState('none');
    console.log("e ",e);

    if(e.status==='4')
    {
        toast.error(e.message);
    }
    else if(e.status===3)
    {
        toast.error("Something Went Wrong");
    }
    else if(e.status===5)
    {
        toast.error("Network Error");
    }
    else if(e.status===1)
    {
        setData(e.winners);
    }
    else
    {
        toast.error("Something Went Wrong");
    }
  }

  //Method to handle search button
  const handleSearch=()=>{
    setDivState('block');
    hitOnBackend();
  }

  //Loader Stuff
  let Loader=require('react-loader');

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('block');

  //State for Loader
  // eslint-disable-next-line 
  const[loaderState,setLoaderState]=useState(false);

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- subscribers-sec --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="firstTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Airtime Logs</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form ss">
            {/* <!-- date --> */}
            <div className="date-inner date-1-sec">
              
              <div className="end-date">
                <label htmlFor="from">Start Date:</label>
                <input
                  type="date"
                  id="from"
                  name="trip-start"
                  onChange={(e)=>{                    
                    setDates({...dates,from:e.target.value})
                  }}
                />
              </div>
              <div className="end-date">
                <label htmlFor="to">End Date:</label>
                <input
                  type="date"
                  id="to"
                  name="trip-start"
                  onChange={(e)=>{                    
                    setDates({...dates,to:e.target.value})
                  }}
                />
              </div>

              <div className="date-search-btn">
                <button type="submit" onClick={()=>{
                  handleSearch();
                }}>Search</button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Airtime Logs</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box" style={{marginBottom:"25px"}}>

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Msisdn</th>
                    <th>Amount</th>
                    <th>Request</th>
                    <th>Response</th>
                    <th>Type</th>
                    <th>Status</th>
                  </tr>
                  {
                    data.length>0 && data.map((value,index)=>{
                      return(
                        <tr key={index}>
                        <td>{value.airtimeDate}</td>
                        <td>{value.msisdn}</td>
                        <td>{value.amount}</td>
                        <td>{value.request}</td>
                        <td>{value.response}</td>
                        <td>{value.type}</td>
                        <td>{value.status}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>

            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default AirtimeLogs;