import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendRevenueApi } from "../Data/Api";
import Post from "../Request/Post";
import {options} from "../Data/LoaderData";

const Revenue = () => {

  //Hook to store date difference
  const [day,setDays]=useState(0);

  //Hook to store data
  const[data,setData]=useState({});

  //to load on start
  useEffect(()=>{
    let request={day:day};
    hitOnBackend(sendRevenueApi,request);
    // eslint-disable-next-line
  },[]);

  //Method to hit on backend
  const hitOnBackend=(url,request)=>{
    let promise=Post(url,request);
    promise.then(e=>{
      handleResponse(e);
    })
  }

 //to handle date
 const handleDate=(chooseDate)=>{

  //Choosed Date
  let date=new Date(chooseDate);
  date=makeDateFormat(date);
  console.log("choosedDate ",chooseDate);

  //New Date
  let currentDate=makeDateFormat(new Date());
  ////console.log("currentDate ",currentDate);

  //Subtracting Dates
  let diffTime = Math.abs(new Date(currentDate) - new Date(date));
  let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  //console.log("days ",days);
  setDays(days);
}

//Method to make dates in format
const makeDateFormat=(date)=>{
  let year=date.getFullYear();
  let month=date.getMonth()+1<10?"0".concat(date.getMonth()+1):date.getMonth()+1;
  let day=date.getDate()<10?"0".concat(date.getDate()):date.getDate();
  date=year+"-"+month+"-"+day;
  return date;
}

//Method to Handle search button
const handleSearchButton=()=>{
  let request={day:day};
  //console.log("searchRequest ",request);
  hitOnBackend(sendRevenueApi,request);
  setDivState('block');
}

//Method to handle response
const handleResponse=(e)=>{
  //console.log("e ",e);

  if(e.status==='5')
  {
    toast.error("Network Error");
  }
  else if(e.status==='0')
  {
    toast.error("Something Went Wrong");
  }
  else if(e.status==='1')
  {
    //ok
    setData({subRevenue:e.subRevenue,renRevenue:e.renRevenue,date:e.date});
    setDivState('none');
  }
}

//Loader Stuff
let Loader=require('react-loader');

//Hook to store starting div state(loading div)
const[divState,setDivState]=useState('block');

//State for Loader
// eslint-disable-next-line 
const[loaderState,setLoaderState]=useState(false);

  return (
    <>

      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Revenue</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form">
            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Revenue</span>
              </p>
            </div>

            {/* <!-- date --> */}
            <div className="date-inner">
            <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={(e)=>{                    
                    handleDate(e.target.value);
                  }}
                />
              </div>
              <div className="date-search-btn">
                <button className="bg-orange" type="submit"
                  onClick={()=>{
                    handleSearchButton();
                  }}>
                  Search
                </button>
              </div>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>New Subscritpion Revenue</th>
                    <th>Renewals Revenue</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{data.date}</td>
                    <td>{data.subRevenue}</td>
                    <td>{data.renRevenue}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default Revenue;