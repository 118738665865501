import axios from "axios"
const Post=async(url,request)=>{

    let sendResponse;

    await axios.post(url,request)
    .then(
        (response)=>{
            // console.log("response ",response);
            sendResponse=response.data;
        },
        (error)=>{
            console.log("error ",error);
            let response={"status":5};
            sendResponse=response;
        }
    )
    return sendResponse;
}
export default Post;