// const pre=`http://localhost:8972/`;
// const pre=`http://88.99.5.236:8989/`;
const pre = `https://www.backendreport.econetbigcash.com/`;

const loginApi=`${pre}login`;
export{loginApi};

const sendLogsApi=`${pre}sendLogs`;
export{sendLogsApi};

const sendRevenueApi=`${pre}sendRevenue`;
export{sendRevenueApi};

const sendSubAndUnsubDataApi=`${pre}sendSubAndUnsubData`;
export{sendSubAndUnsubDataApi};

const sendWinnersApi=`${pre}sendWinners`;
export{sendWinnersApi};

const projectToken=`KJK@#$#JKAREZJK#$#JK`;
export{projectToken};