import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendLogsApi } from "../Data/Api";
import Post from "../Request/Post";
import {options} from "../Data/LoaderData";

//Logs Page
const Logs = () => {

  //Hook to store table data
  const[data,setData]=useState([]);

  //Hook to store current Page
  const[currentPage,setCurrentPage]=useState(0);
  
  //Hook to store totalPages
  // eslint-disable-next-line 
  const[totalPage,setTotalPage]=useState(0);
  
  const[days,setDays]=useState(0);

  const[type,setType]=useState('1');

  //Call on first load
  useEffect(()=>{
    let request={day:days,status:type,size:10,currentPage:currentPage};
    hittingBackendApi(sendLogsApi,request);
    //stauts=1 - for success & status=988 - failure
    // eslint-disable-next-line
  },[]);

  //Method to handle pagination
  const handlePagination=(page)=>{
    //console.log("Page : ",page);
    let request={day:days,status:type,size:10,currentPage:page};
    //console.log("paginationRequest ",request);
    hittingBackendApi(sendLogsApi,request);
    setDivState('block');
  }

  //to handle date
  const handleDate=(chooseDate)=>{

    //Choosed Date
    let date=new Date(chooseDate);
    date=makeDateFormat(date);
    //console.log("choosedDate ",date);

    //New Date
    let currentDate=makeDateFormat(new Date());
    //console.log("currentDate ",currentDate);

    //Subtracting Dates
    let diffTime = Math.abs(new Date(currentDate) - new Date(date));
    let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    //console.log("days ",days);
    setDays(days);
  }

  //Method to make dates in format
  const makeDateFormat=(date)=>{
    let year=date.getFullYear();
    let month=date.getMonth()+1<10?"0".concat(date.getMonth()+1):date.getMonth()+1;
    let day=date.getDate()<10?"0".concat(date.getDate()):date.getDate();
    date=year+"-"+month+"-"+day;
    return date;
  }

  //To handle search button
  const handleSearch=()=>{
    let request={day:days,status:type,size:10,currentPage:0};
    //console.log("searchRequest ",request);
    hittingBackendApi(sendLogsApi,request);
    setDivState('block');
  }

  //Call data from backend
  const hittingBackendApi=(url,request)=>{
    let promise=Post(url,request);
    promise.then(e=>{
      handleResponse(e);
    })
  }

  //handle response
  const handleResponse=(e)=>{
    // console.log("e ",e);

    if(e.status==='5')
    {
      toast.error("Network Error");
    }
    else if(e.status==='0')
    {
      toast.error("Something Went Wrong");
    }
    else if(e.status==='1')
    {
      setData(e.data);
      setCurrentPage(e.currentPage);
      setTotalPage(e.totalPage);

      if(e.data.length===0)
      {
        toast.success("No Data Found");
      }

      setDivState('none');
    }
  }

  //Loader Stuff
  let Loader=require('react-loader');

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('block');

  //State for Loader
  // eslint-disable-next-line 
  const[loaderState,setLoaderState]=useState(false);

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- subscribers-sec --> */}
      <ToastContainer/>
      <Header/>
      <Sidebar/>
      <div id="firstTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Billing Logs</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form ss">
            {/* <!-- date --> */}
            <div className="date-inner date-1-sec">
              <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={(e)=>{                    
                    handleDate(e.target.value);
                  }}
                />
              </div>
              <div className="end-date">
                <label htmlFor="start">Choose Log Type:</label>
                <select id="start" name="start"
                  onClick={(e)=>{
                    setType(e.target.value);
                  }}>
                  <option>Choose</option>
                  <option value="1">Success Billing</option>
                  <option value="988">Fail Billing</option>
                </select>
              </div>
              <div className="date-search-btn">
                <button type="submit" onClick={()=>{
                  handleSearch();
                }}>Search</button>
              </div>
            </div>

            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Logs</span>
              </p>
            </div>

            {/* <!-- slect box --> */}
            <div className="main-box">

              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <tbody>
                  <tr>
                    <th>S.No</th>
                    <th>Msisdn</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Request</th>
                    <th>Response</th>
                  </tr>
                  {
                    data.length>0 && data.map((value,index)=>{
                      return(
                        <tr key={index}>
                        <td>{index+1}</td>
                        <td>{value.ani}</td>
                        <td>{value.total_amount}</td>
                        <td>{value.datetime}</td>
                        <td>{value.type_event}</td>
                        <td>{value.recordstatus===1?'Success':'Fail'}</td>
                        <td>{value.request}</td>
                        <td>{value.response}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>

              {/* <!-- pag --> */}
              <div className="pagination-sec">
                <div className="add-pagination">
                  <p>
                    {/* Showing <span>1</span> to <span>10</span> of <span>49</span>{" "}
                    entries */}
                  </p>
                </div>
                <div className="pagination-next">
                  <ul className="pagination">
                    <li className="page-item">
                      <span className="page-link" 
                      onClick={()=>{
                        handlePagination(currentPage===0?currentPage:currentPage-1);
                      }}>
                        Previous
                      </span>
                    </li>
                    <li className="page-item">
                      <span className="page-link"
                        onClick={()=>{
                          handlePagination(currentPage+1);
                        }}>
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default Logs;