import './App.css';
import Routing from './Routes/Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./CSS/style.css";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function App() {

  const navigate=useNavigate();

  useEffect(()=>{
    let token=localStorage.getItem("token");
    if(token===null||token===''||token===' ')
    {
      navigate("/");
    }
  },[navigate]);

  return (
   <Routing/>
  );
}

export default App;