import React from "react";
import {Routes,Route} from "react-router-dom"
import Login from "../Pages/Login";
import Revenue from "../Pages/Revenue";
import Logs from "../Pages/Logs";
import SubUnsub from "../Pages/SubUnsub";
import AirtimeLogs from "../Pages/AirtimeLogs";

const Routing=()=>{
    return(
        <>
            {/* <BrowserRouter> */}
                <Routes>
                    <Route element={<Login/>} path="/" exact={true}></Route>
                    <Route element={<Logs/>} path="/logs" exact={true}></Route>
                    <Route element={<Revenue/>} path="/revenue" exact={true}></Route>
                    <Route element={<SubUnsub/>} path="/subUnsubData" exact={true}></Route>
                    <Route element={<AirtimeLogs/>} path="/airtimeLogs" exact={true}/>
                </Routes>
            {/* </BrowserRouter> */}
        </>
    );
}
export default Routing;