//Loader
let options = {
  lines: 18,
  length: 10,
  width: 7,
  radius: 30,
  scale: 1.00,
  corners: 1,
  color: 'orange',
  opacity: 0.85,
  rotate: 2,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: '60%',
  left: '50%',
  shadow: false,
  hwaccel: false,
  position: 'absolute'
};
export{options};