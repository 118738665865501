import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import logo from "../Images/avatar.png";
import { loginApi } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import { projectToken } from "../Data/Api";

const Login = () => {

  //to go on other page
  const navigate=useNavigate();

  //To handle login button
  const handleLoginButton=()=>{
    setDivState('block');
    checkingBlankValues();
  }

  //Method to check blank or null values
  const checkingBlankValues=()=>{

    let usernameResult=true;
    let passwordResult=true;

    if(credentials.username===''||credentials.username===' '
        ||credentials.username===null||credentials.username===undefined)
        {
          //console.log("Please Enter Username");
          toast.error("Enter Username First !!");
          usernameResult=false;
        }
    if(credentials.password===''||credentials.password===' '
        ||credentials.password===null||credentials.password===undefined)
        {
          //console.log("Please Enter Password");
          toast.error("Enter Password First !!");
          passwordResult=false;
        }

    if(usernameResult===true && passwordResult===true)
    {
      //Can send to check Credentials
      //console.log("Valid");
      matchCredentials();
    }
    else
    {
      //not correct
      //console.log("Not Valid");
      setDivState('none');
    }
  }

  //Method to match credentials from backend
  const matchCredentials=()=>{
    let promise=Post(loginApi,credentials);
    promise.then(e=>{
      handleApiResponse(e);
    })
}

//Method to handle Api Response
const handleApiResponse=(e)=>{
  // console.log("e ",e);
  setDivState('none');

  if(e.status==='5')
  {
    toast.error("Network Error");
  }
  else if(e.status==='0')
  {
    toast.error("Something Went Wrong");
  }
  else if(e.status==='1')
  {
    //ok response - checking message
    if(e.message==='valid')
    {
      navigate("/logs");
      localStorage.setItem("token",projectToken);
    }
    else if(e.message==='invalid')
    {
      toast.error("Wrong Credentials");
    }
    else
    {
      toast.error("Something Went Wrong");
    }
  }
}

//Hook to store user credentials
const[credentials,setCredentials]=useState({});

//Loader Stuff
let Loader=require('react-loader');

//Hook to store starting div state(loading div)
const[divState,setDivState]=useState('none');

//State for Loader
// eslint-disable-next-line 
const[loaderState,setLoaderState]=useState(false);

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

    <ToastContainer/>
      <section className="login-sec">
        <div className="login-heading">
          <h2>Live Revenue Dashboard</h2>
        </div>
        <div className="login-box">
          <div className="login-inner-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <div className="form">
              <div className="succe-massge">
                <p>Enter Credentials</p>
              </div>
              <div className="user">
                <span className="icon-u">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
                <span>
                  <input
                    type="text"
                    placeholder="Username"
                    name="uname"
                    required
                    onChange={(e)=>{
                      setCredentials({...credentials,username:e.target.value});
                    }}
                  />
                </span>
              </div>
              <div className="password">
                <span className="icon-l">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
                <span>
                  <input
                    type="password"
                    placeholder="Password"
                    name="psw"
                    required
                    onChange={(e)=>{
                      setCredentials({...credentials,password:e.target.value});
                    }}
                  />
                </span>
              </div>
              <div className="submit-btn" 
              onClick={()=>{
                handleLoginButton();
              }}>
                <button type="submit">
                  <span>
                    <i className="fa fa-sign-in" aria-hidden="true"></i>
                  </span>{" "}
                  <span>Login</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
